import { createRouter } from 'router5';
import { mobxPlugin } from 'mobx-router5';
import loggerPlugin from 'router5/plugins/logger';
import browserPlugin from 'router5/plugins/browser';

import routes from './routes';
import stores  from './stores';
// See router5 for more details about this file
export default function configureRouter() {
    return createRouter(routes, { allowNotFound: true,  queryParamsMode: 'loose' })
        .usePlugin(browserPlugin({ useHash: false }))
        .usePlugin(mobxPlugin(stores.routerStore)) 
        .usePlugin(loggerPlugin); 
}
