
//import NotFoundPage from './pages/NotFound/NotFound';
import FinanceComponent from './pages/FinanceComponent/FinanceComponent';


export default [
    {
        name: 'FinanceComponent', 
        path: '/',
        component: FinanceComponent
    }

];